/* You can add global styles to this file, and also import other style files */
@use "sass:math";

/* Importing Bootstrap SCSS file. */
@import "../../node_modules/bootstrap/scss/bootstrap";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.center {
    display: grid;
    place-items: center;
}

.pointer {
    cursor: pointer;
}

.btn-primary {
    --bs-btn-bg: var(--primary);
    --bs-btn-border-color: var(--primary);
    --bs-btn-hover-bg: var(--primary);
    --bs-btn-hover-border-color: var(--primary);
    --bs-btn-active-border-color: var(--primary);
    --bs-btn-disabled-bg: var(--primary);
    --bs-btn-disabled-border-color: var(--primary);
    --bs-btn-color: var(--background);
    --bs-btn-active-color: var(--background);
    --bs-btn-hover-color: var(--background);
    --bs-btn-disabled-color: var(--background);
}
.btn-outline-primary {
    --bs-btn-color: var(--primary);
    --bs-btn-border-color: var(--primary);
    --bs-btn-hover-bg: var(--primary);
    --bs-btn-hover-border-color: var(--primary);
    --bs-btn-active-bg: var(--primary);
    --bs-btn-active-border-color: var(--primary);
    --bs-btn-disabled-color: var(--primary);
    --bs-btn-disabled-border-color: var(--primary);
    --bs-btn-hover-color: var(--primary-text);
    --bs-btn-active-color: var(--primary-text);
}
.mat-primary {
    --mdc-filled-button-label-text-color: var(--background) !important;
}

@for $i from 1 through 20 {
    .w-#{$i*5} {
      width: math.percentage($i*0.05) !important;
    }
    .h-#{$i*5} {
      height: math.percentage($i*0.05) !important;
    }
}
  
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    @for $i from 1 through 20 {
      .w-md-#{$i*5} {
        width: math.percentage($i*0.05) !important;
      }
      .h-md-#{$i*5} {
        height: math.percentage($i*0.05) !important;
      }
    }
}
  
// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    @for $i from 1 through 20 {
      .w-lg-#{$i*5} {
        width: math.percentage($i*0.05) !important;
      }
      .h-lg-#{$i*5} {
        height: math.percentage($i*0.05) !important;
      }
    }
}
  
// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    @for $i from 1 through 20 {
      .w-xl-#{$i*5} {
        width: math.percentage($i*0.05) !important;
      }
      .h-xl-#{$i*5} {
        height: math.percentage($i*0.05) !important;
      }
    }
}
  
// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    @for $i from 1 through 20 {
      .w-xxl-#{$i*5} {
        width: math.percentage($i*0.05) !important;
      }
      .h-xxl-#{$i*5} {
        height: math.percentage($i*0.05) !important;
      }
    }
}