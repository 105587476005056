
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.core();

$light-theme: (
  primary: #EC7700,
  secondary: #C6BAAA,
  accent: #806C61,
  alternate: unset,
  primary-text: #2C2C2C,
  secondary-text: #EC7700,
  background: #FFFFFF,
  background-footer: #323232,
  surface: #2C2C2C
);

$dark-theme: (
  primary: #EC7700,
  secondary: #C6BAAA,
  accent: #806C61,
  alternate: unset,
  primary-text: #bdbdbd,
  secondary-text: #EC7700,
  background: #2C2C2C,
  background-footer: #323232,
  surface: #141414
);

/// Classes de definição de tema, não mexer
  :root {
    // Definindo as variáveis padrão
    @each $key, $value in $light-theme {
      --#{$key}: #{$value};
    }
  }
  .dark-theme {
    // Definindo as variáveis para o tema escuro
    @each $key, $value in $dark-theme {
      --#{$key}: #{$value};
    }
  }
  .light-theme {
    // Definindo as variáveis para o tema claro
    @each $key, $value in $light-theme {
      --#{$key}: #{$value};
    }
  }


/// Texto principal
  .primary-text {
    color: var(--primary-text) !important;
  }
/// Texto secundário
  .secondary-text {
    color: var(--secondary-text) !important;
  }
/// Fundo
  .background {
    background-color: var(--background) !important;
  }

  .background-footer {
    background-color: var(--background-footer) !important;
  }
/// Objeto sobre fundo
  .surface {
    background-color: var(--surface) !important;
  }
  .transparent {
    background-color: transparent !important;
  }

/// Facilitadores para uso das variáveis principais
  .primary {
    color: var(--primary) !important;
  }
  .secondary {
    color: var(--secondary) !important;
  }
  .accent {
    color: var(--accent) !important;
  }
  .alternate {
    color: var(--alternate) !important;
  }
  .bg-primary {
    background-color: var(--primary) !important;
  }
  .bg-secondary {
    background-color: var(--secondary) !important;
  }
  .bg-accent {
    background-color: var(--accent) !important;
  }
  .bg-alternate {
    background-color: var(--alternate) !important;
  }
  .color-background {
    color: var(--background) !important;
  }
  .color-surface {
    color: var(--surface) !important;
  }
  .border-primary {
    border-color: var(--primary) !important;
  }
  .border-secondary {
    border-color: var(--secondary) !important;
  }
  .border-accent {
    border-color: var(--accent) !important;
  }
  .border-alternate {
    border-color: var(--alternate) !important;
  }



/// Material UI Theme
  $mat-palete: (
    100: map-get($light-theme, accent),
    200: map-get($light-theme, accent),
    300: map-get($light-theme, accent),
    400: map-get($light-theme, primary),
    500: map-get($light-theme, primary),
    600: map-get($dark-theme, primary),
    700: map-get($dark-theme, primary),
    800: map-get($dark-theme, accent),
    900: map-get($dark-theme, accent),
    contrast: (
      100: map-get($light-theme, accent),
      200: map-get($light-theme, accent),
      300: map-get($light-theme, accent),
      400: map-get($light-theme, primary),
      500: map-get($light-theme, primary),
      600: map-get($dark-theme, primary),
      700: map-get($dark-theme, primary),
      800: map-get($dark-theme, accent),
      900: map-get($dark-theme, accent),
    ),
  );
  $mat-light-primary: mat.define-palette($mat-palete, 500);
  $mat-light-accent: mat.define-palette($mat-palete, 300);
  $mat-dark-primary: mat.define-palette($mat-palete, 700);
  $mat-dark-accent: mat.define-palette($mat-palete, 900);
  $mat-dark-theme: mat.define-dark-theme((
    color: (
      primary: $mat-dark-primary,
      accent: $mat-dark-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  ));
  $mat-light-theme: mat.define-light-theme((
    color: (
      primary: $mat-light-primary,
      accent: $mat-light-accent,
    ),
  ));

  // Apply the dark theme by default
  @include mat.all-component-themes($mat-dark-theme);

  // Apply the light theme only when the user prefers light themes.
  @media (prefers-color-scheme: light) {
    @include mat.all-component-themes($mat-light-theme);
  }
